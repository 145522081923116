/*===== BUTTONS =====*/
.btn {
  &.btn-square {
    border-radius: 0px;
  }
  &.btn-pill {
    border-radius: 100px;
  }
}

/* Button Social */
.btn-facebook,
.btn-twitter,
.btn-google-plus,
.btn-linkedin,
.btn-pinterest,
.btn-tumblr,
.btn-vimeo,
.btn-skype,
.btn-dropbox {
  position: relative;
  overflow: hidden;
  color: $white;
  text-align: center;
  padding:  0.312rem 0.625rem;
  font-size: 0.98rem;
  border: 1px solid transparent;
  line-height: 1.5;
  width: 40px;
  height: 40px;
  &.text {
    width: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    i {
      margin-right: .5rem;
    }
  }
  &.btn-lg {
    height: 50px;
    padding-left: 1.88rem;
    padding-right: 1.88rem;
  }
}

.btn-facebook {
  background: #3b5998;
  &.btn-outline {
    color: #3b5998;
    border-color: rgba(#3b5998, 0.6);
    background: transparent;
  }
}

.btn-facebook:hover {
  color: $white;
  background: #344e86;
  &.btn-outline {
    color: $white;
    border-color: rgba(#3b5998, 1);
  }
}

.btn-facebook:hover::before {
  background: #2d4373;
}

.btn-twitter {
  background: #00aced;
  &.btn-outline {
    color: #00aced;
    border-color: rgba(#00aced, 0.6);
    background: transparent;
  }
}

.btn-twitter:hover {
  color: $white;
  background: #0099d4;
  &.btn-outline {
    color: $white;
    border-color: rgba(#00aced, 1);
  }
}

.btn-skype {
  background: #00aff0;
  &.btn-outline {
    color: #00aff0;
    border-color: rgba(#00aff0, 0.6);
    background: transparent;
  }
}

.btn-skype:hover {
  color: $white;
  background: #00aff0;
  &.btn-outline {
    color: $white;
    border-color: rgba(#00aff0, 1);
  }
}

.btn-skype:hover::before {
  background: #0087ba;
}

.btn-linkedin {
  background: #4875b4;
  &.btn-outline {
    color: #4875b4;
    border-color: rgba(#4875b4, 0.6);
    background: transparent;
  }
}

.btn-linkedin:hover {
  color: $white;
  background: #4169a2;
  &.btn-outline {
    color: $white;
    border-color: rgba(#4875b4, 1);
  }
}

.btn-linkedin:hover::before {
  background: #395d90;
}

.btn-pinterest {
  background: #cb2027;
  &.btn-outline {
    color: #cb2027;
    border-color: rgba(#cb2027, 0.6);
    background: transparent;
  }
}


.btn-pinterest:hover {
  color: $white;
  background: #b51d23;
  &.btn-outline {
    color: $white;
    border-color: rgba(#cb2027, 1);
  }
}

.btn-pinterest:hover::before {
  background: #9f191f;
}

.btn-tumblr {
  background: #32506d;
  &.btn-outline {
    color: #32506d;
    border-color: rgba(#32506d, 0.6);
    background: transparent;
  }
}

.btn-tumblr:hover {
  color: $white;
  background: #2a435c;
  &.btn-outline {
    color: $white;
    border-color: rgba(#32506d, 1);
  }
}

.btn-tumblr:hover::before {
  background: #22364a;
}

.btn-google-plus {
  background: #d34836;
  &.btn-outline {
    color: #d34836;
    border-color: rgba(#d34836, 0.6);
    background: transparent;
  }
}

.btn-google-plus:hover {
  color: $white;
  background: #c43d2b;
  &.btn-outline {
    color: $white;
    border-color: rgba(#d34836, 1);
  }
}

.btn-google-plus:hover::before {
  background: #b03626;
}

.btn-dropbox {
  background: #007ee5;
  &.btn-outline {
    color: #007ee5;
    border-color: rgba(#007ee5, 0.6);
    background: transparent;
  }
}

.btn-dropbox:hover {
  color: $white;
  background: #0070cc;
  &.btn-outline {
    color: $white;
    border-color: rgba(#007ee5, 1);
  }
}

.btn-dropbox:hover::before {
  background: #0062b2;
}

.btn-vimeo {
  background: #aad450;
  &.btn-outline {
    color: #aad450;
    border-color: rgba(#aad450, 0.6);
    background: transparent;
  }
}

.btn-vimeo:hover {
  color: $white;
  background: #a0cf3c;
  &.btn-outline {
    color: $white;
    border-color: rgba(#aad450, 1);
  }
}

.btn-vimeo:hover::before {
  background: #93c130;
}

.social-button .btn {
  border-color: $border-color;
  line-height: 28px;
  color: $secondary;
  &:hover {
    border-color: $primary;
    color: $white;
    background: $primary;
  }
}
/*===== 4.12 DROPDOWN =====*/
.btn,
.dropdown-toggle {
  &:focus, &:active, &.active {
    box-shadow: none!important;
  }
}
.dropdown-toggle::after {
  border-right: 5px solid transparent;
  border-bottom: 0px;
  border-top: 6px solid;
  border-left: 5px solid transparent;
  vertical-align: middle;
}

/* Button Split */
.dropdown-toggle-split {
  padding: 0 .75rem;
}

/*===== BUTTON LOADING =====*/
.btn.btn-ladda {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  @include button-size(auto,20px, 15px, auto, 0px);
}
