/*===== WIDGETS =====*/
.widget-block {
  position: relative;
  padding: 1.9rem;
  margin-bottom: 1.9rem;
  &.bg-primary {
    border-color: $primary;
    p {
      color: rgba($white, 0.7);
    }
    .progress {
      background: rgba($white, 0.5);
    }
  }
  &.bg-warning {
    border-color: $warning;
    p {
      color: rgba($white, 0.7);
    }
    .progress {
      background: rgba($white, 0.5);
    }
  }
  &.bg-danger {
    border-color: $danger;
    p {
      color: rgba($white, 0.7);
    }
    .progress {
      background: rgba($white, 0.5);
    }
  }
  &.bg-success {
    border-color: $success;
    p {
      color: rgba($white, 0.7);
    }
    .progress {
      background: rgba($white, 0.5);
    }
  }

  i {
    font-size: 1.38rem;
  }
}
.widget-chart {
  height: 70px;
  #barChart {
    width: 100%;
    height: 100%;
  }
}
.widget-media {
  position: relative;
  padding: 1.9rem;
  margin-bottom: 1.9rem;
  .icon {
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
  }
  i {
    font-size: 1.38rem;
  }
}
.widget-profile {
  .card-img {
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
}

/* Widgets Dropdown */
.widget-dropdown {
  .dropdown-toggle {
    color: $secondary;
    text-transform: uppercase;

    &.icon-burger-mini {
      font-size: 1.5rem;
      &:after {
        display: none;
      }
      position: relative;
      &:before {
        font-family: $font-family-icon;
        content: "\F1D9";
      }
    }
  }
  .dropdown-menu {
    padding-top: .5rem;
    padding-bottom: .5rem;
    box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
    min-width: auto;
    .dropdown-item {
      padding: 0.3125rem 1.25rem;
      a {
        font-size: 0.875rem;
        color: $secondary;
        text-transform: capitalize;
      }
      &:hover {
        background-color: transparent;
        a {
          color: $primary;
        }
      }
    }
  }
}
