/*===== FONTS SIZE =====*/
.font-size-10 {
	font-size: 0.625rem;
}
.font-size-11 {
	font-size: 0.69rem;
}
.font-size-12 {
	font-size: .75rem;
}
.font-size-13 {
	font-size: .81rem;
}
.font-size-14 {
	font-size: .88rem;
}
.font-size-15 {
	font-size: 0.94rem;
}
.font-size-16 {
	font-size: 1rem;
}
.font-size-17 {
	font-size: 1.06rem;
}
.font-size-18 {
	font-size: 1.13rem;
}
.font-size-19 {
	font-size: 1.9rem;
}
.font-size-20 {
	font-size: 1.25rem;
}
