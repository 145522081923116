// Chat 
.chat {
  border-radius: 0.25rem;

  .chat-left-side {
    .chat-search {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
 
    #chat-left-content {
      height: 373px !important;
      @include media-breakpoint-up(lg){
        height: calc(100vh - 320px) !important;
      }
      @include media-breakpoint-up(xl) {
        height: calc(100vh - 350px) !important;
      }
    }    
    .last-msg {
      @include media-breakpoint-up(md) {
        width: 300px;
      }
      @include media-breakpoint-up(lg) {
        width: 125px;
      }
      @include media-breakpoint-up(xxl) {
        width: 220px;
      }
    }
  }

  .chat-right-side {
    border-left: 1px solid $border-color;

    .chat-right-content {
      padding: 1.5rem;
    }
 
    #chat-right-content {
      height: 373px !important;      
      @include media-breakpoint-up(lg) {
        height: calc(100vh - 395px) !important;
      }
      @include media-breakpoint-up(xl) {
        height: calc(100vh - 405px) !important;
      }
    }    
  }  
  
}
