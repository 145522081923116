/*===== CIRCLE PROGRESS =====*/

.circle {
  position: relative;
  text-align: center;
  .circle-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    h6, strong {
      font-size: 12px;
    }
  }
  &.circle-lg {
    h6 {
      font-size: 45px;
      span {
        font-size: 35px;
      }
    }
  }
}