/*===== LIST GROUP =====*/
.list-group {
  .list-group-item {
    padding: 1.38rem;
  }
}

.list-styled > li {
  list-style-type: disc ;
  margin-left: 0.94rem;
  margin-right: 0.94rem;
}
